<template>
	<div id="home" class="lms-tab-view">
		<nav-bar class="landing-mode" :nav-settings="navSettings"></nav-bar>
		<section class="landing lms-section">
			<div class="lms-bubble lms-bubble-animation"></div>
			<div class="lms-content-frame">
				<div class="content">
					<img class="lms-texture" src="@/assets/xoxo_shape.svg" alt />
					<h1 v-html="getContent.landing.title[getLanguage]"></h1>
					<p v-html="getContent.landing.subtitle[getLanguage]"></p>
					<div class="button-container">
						<button class="lms-main-button transparent" v-scroll-to="{
							el: '.our-services',
							duration: 1300,
							offset: 100
						}">{{ getContent.landing.button1[getLanguage] }}</button>
						<button class="lms-main-button transparent" v-scroll-to="{
							el: '.contact',
							duration: 1300,
							offset: -50,
						}">{{ getContent.landing.button2[getLanguage] }}</button>
						<router-link :to="{ path: '/apply' }">
							<button class="lms-main-button apply-pulse">
								{{ getContent.landing.button_apply_now[getLanguage] }}
							</button>
						</router-link>
					</div>
				</div>
			</div>
			<button v-scroll-to="{
				el: '.why-lumos',
				offset: -50,
			}" class="scroll-down"><img src="@/assets/arrow-down-circle.svg" alt /></button>
		</section>
		<section id="why-lumos" class="why-lumos lms-section">
			<div class="lms-content-frame vertical">
				<div class="lms-section-head">
					<h2 v-html="getContent.whyLumos.title[getLanguage]"></h2>
					<p v-html="getContent.whyLumos.text[getLanguage]"></p>
				</div>
			</div>
			<div class="lms-skew"></div>
		</section>
		<section id="services" class="our-services lms-section">
			<div class="lms-content-frame vertical">
				<div class="lms-section-head">
					<h2 v-html="getContent.services.title[getLanguage]"></h2>
					<p v-html="getContent.services.subtitle[getLanguage]"></p>
				</div>
				<div v-for="section in getContent.services.sections" :key="section.title" class="lms-description-box"
					:class="{ reverse: section.orientation[getLanguage] === 'reverse' }">
					<div class="content">
						<h2>{{ section.title[getLanguage] }}</h2>
						<p>{{ section.text[getLanguage] }}</p>
					</div>
					<img :src="section.image[getLanguage]" alt />
				</div>
			</div>
		</section>


		<section v-if="activeEvents(getContent.events.events[getLanguage]).length > 0" id="lumos-events"
			class="lumos-events  active-events-container">

			<div id="events_page_container" class="lms-content-frame vertical">
				<div class="lms-section-head">
					<h2 id="team_title" class="title" style="color:#313131"
						v-html="getContent.events.title[getLanguage]"></h2>
					<p v-html="getContent.events.subtitle[getLanguage]"></p>
				</div>

				<div class="event-cards-container" :class="eventsListHasOneElement">
					<div v-for="event in activeEvents(getContent.events.events[getLanguage]).sort(compareEventsbyDate).slice(0, 2)"
						:class="eventsListHasOneElement" :key="event.name">
						<Event :event=event :active="true"></Event>
					</div>
				</div>
				<div class="event-background-element"></div>

				<router-link tag="button" to="/events" class="lms-main-button">{{
					getContent.landing.event_view_button[getLanguage]
				}}</router-link>
			</div>


		</section>

		<section id="lumos-coop" class="lumos-coop lms-section">
			<div class="lms-content-frame vertical">
				<div class="lms-section-head">
					<h2 class="title" v-html="getContent.lumosCoop.title[getLanguage]"></h2>
					<p class="subtitle" v-html="getContent.lumosCoop.subtitle[getLanguage]"></p>
				</div>

				<div class="logos">
					<div>
						<a v-for="partner in getContent.lumosCoop.partners[getLanguage]" :key="partner.name"
							class="lms-logo-box" :class="{}" :href="partner.link" target="_blank" :id="partner.id">
							<img :src="partner.image" alt="Partner Logo" />
						</a>
					</div>
				</div>
			</div>
		</section>

		
		<section class="lumos-experience lms-section">

<div class="lms-content-frame vertical">
	<div class="lms-section-head">
		<h2 class="title" v-html="getContent.experiences.title[getLanguage]"></h2>
	</div>
	<splide :options="splideOptions">
  <splide-slide 
    v-for="experience in getContent.experiences.experiences[getLanguage].slice(0, 4)" 
    :key="experience.id"
    class="lumos-experience-slider" 
    :id="experience.id"
  >
    <div class="content">
      <div class="experience-icon-container">
        <img class="experience-icon" :src="experience.image" alt />
      </div>
      <h3 class="title" v-html="experience.title[getLanguage]"></h3>
      <div>
        <div>
          <div class="text">
            <p v-html="experience.text[getLanguage]"></p>
          </div>
        </div>
      </div>
    </div>
  </splide-slide>
</splide>


<!-- //*if you want it to show 8 projects (2 per page) here is the old code:
<splide :options="splideOptions">
  <splide-slide v-for="experience in getContent.experiences.experiences[getLanguage]" :key="experience.id"
    class="lumos-experience-slider" :id="experience.id">
    <div class="content">
      <div class="experience-icon-container">
        <img class="experience-icon" :src="experience.image" alt />
      </div>

      <h3 class="title" v-html="experience.title[getLanguage]"></h3>
      <div>
        <div>
          <div class="text">
            <p v-html="experience.text[getLanguage]"></p>
          </div>
        </div>
      </div>
    </div>
  </splide-slide>
</splide>
*// -->

</div>
</section>


		<!--
		<section id="blog" class="blog lms-section">
			<div class="lms-apply-box">
				<div class="step">
					<div class="instructions">
						<svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.999" viewBox="0 0 22 19.999">
							<path
								d="M7,0a4.992,4.992,0,0,1,4,2,4.991,4.991,0,0,1,4-2h6a1,1,0,0,1,1,1h0V16a1,1,0,0,1-1,1H14a2,2,0,0,0-2,2,.949.949,0,0,1-.845.989l-.1.01h-.1A.957.957,0,0,1,10,19h0l-.005-.149A2,2,0,0,0,8,17H1a1,1,0,0,1-1-1H0V1A1,1,0,0,1,1,0H7ZM7,2H2V15H8a3.982,3.982,0,0,1,1.855.455h0l.145.081V5h0l-.005-.176A3,3,0,0,0,7.176,2.005h0ZM20,2H15a3,3,0,0,0-3,3h0V15.535l.145-.08A3.977,3.977,0,0,1,13.8,15h0l.2,0h6Z" />
						</svg>
						<h3>{{ getContent.blogBox.title[getLanguage] }}</h3>
						<p>{{ getContent.blogBox.subtitle[getLanguage] }}</p>
					</div>
					<div class="action">
						<router-link tag="button" to="/blog" class="lms-main-button transparent">{{ getContent.blogBox.button[getLanguage] }}
						</router-link>
					</div>
				</div>
			</div>
		</section>
		-->


		<section id="contact" class="contact lms-section">
			<div class="lms-content-frame">
				<div class="lms-description-box">
					<div class="content">
						<h2>{{ getContent.contact.title[getLanguage] }}</h2>
						<p v-html="getContent.contact.text[getLanguage]"></p>
						<div class="email-link">
							<svg xmlns="http://www.w3.org/2000/svg" fill="#ef5175" width="22" height="18"
								viewBox="0 0 22 18">
								<path
									d="M19,0a3.006,3.006,0,0,1,3,3h0V15a3.006,3.006,0,0,1-3,3H3a3.006,3.006,0,0,1-3-3H0V3A3.006,3.006,0,0,1,3,0H19Zm1,4.92-8.427,5.9a1,1,0,0,1-1.028.071l-.119-.071L2,4.921V15a1.007,1.007,0,0,0,.884.993L3,16H19a1.006,1.006,0,0,0,1-1h0ZM19,2H3a1.006,1.006,0,0,0-.893.554L11,8.779l8.893-6.225A1.006,1.006,0,0,0,19,2Z" />
							</svg>
							<a href="mailto: office@lumos-consulting.at">office@lumos-consulting.at</a>
						</div>
						<br><br>
					</div>
				</div>

				<div class="contact-form">
					<input v-model="contactName" type="text" class="lms-main-input" name="name"
						:placeholder="getContent.contact.namePlaceholder[getLanguage]" />
					<input v-model="contactEmail" type="text" class="lms-main-input" name="email"
						:placeholder="getContent.contact.emailPlaceholder[getLanguage]" />
					<textarea v-model="contactMessage" class="lms-main-input" rows="10" cols="50"
						:placeholder="getContent.contact.messagePlaceholder[getLanguage]"></textarea>
					<button v-if="!contactSent" type="button" class="lms-main-button big" id="contactFormButton" name="name"
						@click="sendContact">
						{{ getContent.contact.button[0][getLanguage] }}
					</button>
					<div class="contactedDiv" v-else>
						<p v-if="!contactError">{{ getContent.contact.button[1][getLanguage] }}</p>
						<p class="error-message" v-else>{{ getContent.contact.button[2][getLanguage] }}</p>
					</div>

				</div>
			</div>
		</section>

		<Footer :footer-settings="navSettings"></Footer>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Event from "@/components/Event.vue";

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import * as ManualCookies from '@/assets/js/cookie-functions.js'

export default {
	name: "Home",
	components: {
		NavBar,
		Footer,
		Splide,
		SplideSlide,
		Event
	},
	metaInfo() {
		return {
			title: 'Lumos - Student Data Consulting',
			meta: [
				{
					name: 'description',
					content: 'Lumos ist die erste studentische Data Science Beratung in Österreich. Gemeinsam mit unserem Team aus talentierten Studierenden unterstützen wir Unternehmen bei der Anwendung neuer Technologien.'
				},
			]
		}
	},
	data() {
  return {
    splideOptions: {
      type: 'loop',
      perPage: 2,
      gap: '1rem',
      autoplay: true,
      interval: 3000,
      speed: 1000,
      pauseOnHover: false,
      drag: true,
      breakpoints: {
        1100: {
          perPage: 1,
        },
      },
    },
  };
},

	computed: {
		getLanguage() {
			return this.$store.state.language;
		},
		getContent() {
			return this.$store.state;
		},
		navSettings() {
			return [
				{
					title: this.getContent.navTitles[0][this.getLanguage],
					path: "/",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm0,8a1,1,0,0,1,.993.883L12,11v4a1,1,0,0,1-1.993.117L10,15V11A1,1,0,0,1,11,10Zm.01-4a1,1,0,0,1,.117,1.993L11,8a1,1,0,0,1-.117-1.993Z"/></svg>',
					scrollSettings: {
						el: ".why-lumos",
						offset: -120,
					},
				},
				{
					title: this.getContent.navTitles[1][this.getLanguage],
					path: "/",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M19,11a1,1,0,0,1,1,1h0v7a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V12a1,1,0,0,1,1-1h7ZM8,11a1,1,0,0,1,1,1H9v7a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1H0V12a1,1,0,0,1,1-1H8Zm10,2H13v5h5ZM7,13H2v5H7ZM8,0A1,1,0,0,1,9,1H9V8A1,1,0,0,1,8,9H1A1,1,0,0,1,0,8H0V1A1,1,0,0,1,1,0H8ZM19,0a1,1,0,0,1,1,1h0V8a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1h0V1a1,1,0,0,1,1-1h7ZM7,2H2V7H7ZM18,2H13V7h5Z"/></svg>',
					scrollSettings: {
						el: ".our-services",
						offset: 50,
					},
				},
				{
					title: this.getContent.navTitles[2][this.getLanguage],
					path: "/apply",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20"><path d="M13,12a5,5,0,0,1,5,4.783L18,17v2a1,1,0,0,1-1.993.117L16,19V17a3,3,0,0,0-2.824-2.995L13,14H5a3,3,0,0,0-2.995,2.824L2,17v2a1,1,0,0,1-1.993.117L0,19V17a5,5,0,0,1,4.783-5L5,12Zm7.25.162a5,5,0,0,1,3.745,4.611L24,17v2a1,1,0,0,1-1.993.117L22,19V17a3,3,0,0,0-2.25-2.9,1,1,0,1,1,.5-1.936ZM9,0A5,5,0,1,1,4,5,5,5,0,0,1,9,0Zm7.248.161a5,5,0,0,1,0,9.688,1,1,0,0,1-.5-1.937,3,3,0,0,0,0-5.812,1,1,0,0,1,.5-1.938ZM9,2a3,3,0,1,0,3,3A3,3,0,0,0,9,2Z"/></svg>',
					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[3][this.getLanguage],
					path: "/blog",
					icon:
						'<svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.999" viewBox="0 0 22 19.999"><path d="M7,0a4.992,4.992,0,0,1,4,2,4.991,4.991,0,0,1,4-2h6a1,1,0,0,1,1,1h0V16a1,1,0,0,1-1,1H14a2,2,0,0,0-2,2,.949.949,0,0,1-.845.989l-.1.01h-.1A.957.957,0,0,1,10,19h0l-.005-.149A2,2,0,0,0,8,17H1a1,1,0,0,1-1-1H0V1A1,1,0,0,1,1,0H7ZM7,2H2V15H8a3.982,3.982,0,0,1,1.855.455h0l.145.081V5h0l-.005-.176A3,3,0,0,0,7.176,2.005h0ZM20,2H15a3,3,0,0,0-3,3h0V15.535l.145-.08A3.977,3.977,0,0,1,13.8,15h0l.2,0h6Z"/></svg>',
					scrollSettings: {},
				},

				{
					title: this.getContent.navTitles[4][this.getLanguage],
					path: "/team",
					icon:
						'<svg id="Layer_1" viewBox="16 24 60 45" width="25" height="26" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g><path d="M46.252,22.97c-6.738,0-12.219,5.484-12.219,12.223c0,2.725,0.907,5.236,2.42,7.27c-2.98,2.084-4.777,5.191-4.777,8.521   v17.369h3.4V50.983c0-2.371,1.436-4.619,3.804-6.068c2.052,1.561,4.601,2.498,7.372,2.498c2.806,0,5.385-0.959,7.451-2.557   c2.427,1.449,3.897,3.719,3.897,6.127v17.369H61V50.983c0-3.383-1.839-6.516-4.89-8.598c1.479-2.021,2.363-4.504,2.363-7.193   C58.474,28.454,52.991,22.97,46.252,22.97z M46.252,44.015c-4.863,0-8.818-3.959-8.818-8.822c0-4.865,3.955-8.822,8.818-8.822   c4.864,0,8.822,3.957,8.822,8.822C55.074,40.056,51.116,44.015,46.252,44.015z"/><path d="M18.976,48.233c0-1.043,0.596-2.037,1.579-2.768c1.26,0.838,2.768,1.33,4.391,1.33c4.394,0,7.969-3.572,7.969-7.965   c0-4.395-3.575-7.969-7.969-7.969c-4.393,0-7.965,3.574-7.965,7.969c0,1.529,0.453,2.943,1.202,4.156   c-1.642,1.359-2.607,3.242-2.607,5.246v10.084h3.4V48.233z M24.945,34.263c2.52,0,4.568,2.049,4.568,4.568   c0,2.516-2.049,4.564-4.568,4.564c-2.518,0-4.565-2.049-4.565-4.564C20.38,36.312,22.428,34.263,24.945,34.263z"/><path d="M74.422,42.931c0.729-1.201,1.172-2.596,1.172-4.1c0-4.395-3.574-7.969-7.966-7.969c-4.394,0-7.969,3.574-7.969,7.969   c0,4.393,3.575,7.965,7.969,7.965c1.646,0,3.178-0.502,4.448-1.361c1.01,0.73,1.621,1.738,1.621,2.799v10.084h3.4V48.233   C77.098,46.194,76.106,44.288,74.422,42.931z M67.628,43.396c-2.52,0-4.569-2.049-4.569-4.564c0-2.52,2.05-4.568,4.569-4.568   c2.518,0,4.565,2.049,4.565,4.568C72.193,41.347,70.146,43.396,67.628,43.396z"/></g></svg>',

					scrollSettings: {},
				},
				{
					title: this.getContent.navTitles[5][this.getLanguage],
					path: "/events",
					icon:
						'<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V1.89474H17.6315V1C17.6315 0.447715 18.0792 0 18.6315 0C19.1838 0 19.6315 0.447715 19.6315 1V1.93424C22.0948 2.2447 24 4.34723 24 6.89474V19C24 21.7614 21.7614 24 19 24H5C2.23858 24 0 21.7614 0 19V6.89473C0 4.13331 2.23858 1.89474 5 1.89474V1ZM19 3.89474H5C3.34314 3.89474 2 5.23788 2 6.89473V8.10526H22V6.89474C22 5.23788 20.6569 3.89474 19 3.89474ZM2 19V10.1053H22V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19Z"/></svg>',

					scrollSettings: {},
				},
			]
		},
		eventsListHasOneElement: function () {
			return { center: this.activeEvents(this.getContent.events.events[this.getLanguage]).length === 1 };
		},
	},
	methods: {
		activeEvents(events) {
			return events.filter(obj => obj.endDate >= new Date(Date.now()).toISOString())
		},
		inactiveEvents(events) {
			return events.filter(obj => obj.endDate < new Date(Date.now()).toISOString())
		},
		compareEventsbyDate(a, b) {
			if (a.startDate < b.startDate) {
				return -1;
			}
			if (a.startDate > b.startDate) {
				return 1;
			}
			return 0;
		},
		sendContact() {
			
			const validateEmail = (email) => {
				const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
				return re.test(email);
			};
			
			// validation
			if (
				this.contactName == null || this.contactName.trim() === "" ||
				this.contactEmail == null || this.contactEmail.trim() === "" ||
				this.contactMessage == null || this.contactMessage.trim() === ""
			) {
				this.$toast.error(this.getContent.contact.buttonValidationError[this.getLanguage]);
				
			} else if(!validateEmail(this.contactEmail)) {
				this.$toast.error(this.getContent.contact.buttonEmailError[this.getLanguage]);
				
			} else { // validation success
				let notify_btn = document.getElementById('contactFormButton');
				notify_btn.setAttribute("disabled", true);
				notify_btn.innerText = this.getContent.contact.buttonText[this.getLanguage];
				
				const data = {
					"Name": this.contactName,
					"Email": this.contactEmail,
					"Message": this.contactMessage,
				};
				
				fetch('/api/contact', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(data),
				}).then((response) => {
					response.json()
					this.contactSent = true
					if (response.status !== 200) {
						this.contactError = true;
					}
				})
			}
		},
	},
	mounted() {
		if (localStorage.language) {
			this.$store.commit("switchLanguage", localStorage.language);
		}
		this.$store.dispatch('fetchEvents');

		//Statistic
		ManualCookies.sendCookies("Home");
	},
};
</script>

<style lang="scss">
.landing {
	min-height: calc(104vh);
	overflow-x: hidden;

	.content {
		width: 50%;

		.lms-texture {
			height: 60px;
			margin: 0 0 20px 0;
		}
	}

	h1 {
		font-size: 45px;
		font-weight: 400;
	}

	p {
		font-size: 18px;
		line-height: 30px;
		margin: 20px 0 35px 0;
		opacity: 0.6;
	}

	.scroll-down {
		position: absolute;
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		bottom: 60px;
		transition: 0.3s ease-in-out;

		img {
			width: 35px;
		}

		&:hover {
			transform: scale(1.1);
		}
	}

}

.why-lumos {
	text-align: center;
	padding: 50px 0 0 0;

	.lms-content-frame {
		z-index: 5000;
	}
}

.our-services {
	padding: 250px 0 50px 0;
	background: #ffffff;

	.lms-section-head {
		margin: 0 0 150px 0;

	}

	.lms-description-box:nth-of-type(4) {
		margin: 0;
	}
}

.blog {
	.step {
		margin: 80px 0;
	}
}


.lumos-coop {
	padding: 150px 0 0 0;
	background-color: white;
	text-align: center;

	.lms-content-frame {
		z-index: 5000;
	}


	.title {
		padding: 0 20px 0 10px;
	}

	.subtitle {
		padding: 0 20px 0 10px;
	}


	.logos {
		width: 100%;
		margin-top: 50px;
		margin-bottom: 100px;
		overflow: hidden;
		border-radius: 5px;

		div {
			margin: -1px -1px -1px -1px;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			border-radius: 10px;


			.lms-logo-box:hover {
				background-color: rgb(245, 245, 245);
				scale: 0.95;
				border-color: rgb(245, 245, 245);

				img {
					transform: scale(1.05);
					transition: all 0.3s;
				}
			}

			.lms-logo-box {
				transition: all 0.5s;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				float: left;
				width: 33.33333%;
				height: 200px;
				padding: 2.5%;
				border: 1px solid $border-grey;

				img {
					max-width: 80%;
					max-height: 80%;
				}
			}

			#coop_icon:hover {
				background-color: rgba(255, 255, 255, 0);
				pointer-events: none;
				cursor: default;
			}

			#coop_icon {
				padding: 0;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
}

.lumos-experience {
	background-color: white;
	padding: 40px 0;

	.lms-content-frame {
		padding: 0;
	}

	.splide__arrow {

		svg {
			fill: #6a345483;
		}
	}

	.splide__pagination__page:hover,
	.splide__pagination__page.is-active {
		background: #344f6a83;
	}

	.lumos-experience-slider {

		display: flex;
		flex-direction: row;
		justify-content: center;
		overflow: visible;

		.content {
			margin: 40px 20px;
			padding: 40px;
			background-color: white;
			border: 1px solid $border-grey;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;

			span {
				color: $lumos-pink;
			}

			.experience-icon-container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 300px;
				height: 150px;
				margin-bottom: 20px;
				border-radius: 8px;

				.experience-icon {
					width: 120%;
					height: auto;
					max-height: 200%;

					.bi {
						fill: #ef5175;
					}
				}
			}

			div {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-top: 10px;

				div {
					.text {
						p {
							font-family: $open-sans;
							font-size: 15px;
							color: $light-text;
						}
					}
				}
			}
		}

		.person {
			font-size: 24px;
			padding: 0 0 5px 0;

			.lastName {
				color: $lumos-pink;
			}
		}
	}
}



.lumos-meet-team {
	background-color: white;

	.lms-content-frame {
		padding: 0;
	}

	.splide__arrow,
	.splide__arrow:hover,
	svg {
		fill: #344f6a83;

	}

	.splide__pagination__page:hover,
	.splide__pagination__page.is-active {
		background: #344f6a83;
	}

	.lumos-team-slider {

		.content {
			height: 80%;
			margin: 10px;
			padding: 15px;
			background-color: white;
			border: 1px solid $border-grey;
			box-shadow: 0 0 6px lightgray;
			border-radius: 20px;

			div {
				width: 100%;
				height: 100%;

				img {
					display: block;
					position: relative;
					width: 15%;
					max-height: 60%;
					float: left;
					top: 8%;
				}

				div {
					width: 85%;
					float: left;

					.title-right {
						display: flex;
						margin-top: 15px;
						margin-left: 15px;
						font-family: 'Open Sans', sans-serif;
						font-size: 14px;
						width: 95%;
					}

					.title-left {
						float: left;
					}
				}
			}
		}

		.person {
			font-size: 24px;
			padding: 0 0 5px 0;

			.lastName {
				color: $lumos-pink;
			}
		}

	}
}

.splide__arrow:hover svg {
	fill: #0c1e3083;
}

.contact {
	background: #f5f6f8;
	padding: 100px 0;

	.lms-content-frame {
		justify-content: space-between;
	}

	.lms-description-box {
		margin: 0 10% 0 0;

		.content {
			width: 100%;
		}
	}

	.email-link {
		display: flex;
		align-items: center;

		svg {
			height: 16px;
			margin: 0 12px 0 0;
		}

		a {
			color: $lumos-pink;
			text-decoration: none;
			font-family: "Open Sans", sans-serif;
			font-weight: 500;
		}
	}

	.contact-form {
		display: flex;
		flex-direction: column;
		background-color: white;
		border: 1px solid $border-grey;
		border-radius: 5px;
		box-sizing: border-box;
		padding: 40px;
		margin: 0 0 0 auto;
	}

	.contactedDiv {

		margin: -1px -1px -1px -1px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		border-radius: 10px;
		font-size: 24px;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}
}

/* - - - - - - - - - - - - - */
/* -  Mobile Optimization  - */
/* - - - - - - - - - - - - - */

@media screen and (max-width: 768px) {
	.landing {
		min-height: 100vh;

		.content {
			padding: 0 30px;
			box-sizing: border-box;
			width: 100%;

			.lms-texture {
				display: none;
			}
		}

		h1 {
			font-size: 40px;
		}

		p {
			line-height: 26px;
		}
	}

	.why-lumos {
		padding: 80px 0 0 0;
	}

	.our-services {
		padding: 200px 0 0 0;

		.lms-section-head {
			margin: 0 0 100px 0;
		}
	}

	.lms-section-head {

		h2,
		p {
			padding: 0 20px;
		}
	}

	.blog .step {
		margin: 40px 0 80px 0;
	}

	.contact {
		padding: 100px 0;

		.lms-content-frame {
			flex-direction: column;
		}

		.email-link {
			img {
				height: 18px;
				width: 18px;
				margin: 0 15px 0 0;
			}
		}

		.contact-form {
			padding: 30px;
			margin: 30px 0 0 auto;
			width: 100%;
		}

	}

	.lumos-coop {
		padding: 40px 0 0;

		.logos {
			div {
				.lms-logo-box {
					width: 50%;
					height: auto;
					min-height: 50px;

				}

			}
		}
	}

	.lumos-experience {
		.lumos-experience-slider {

			.content {
				padding: 20px;
				margin: 6px;

				.person {
					font-size: 1.2em;
					margin-bottom: 6px;
					padding: 0;
				}

				.title {
					font-size: 1.4em;
				}

				div {
					div {
						width: 100%;

						.text {
							margin-left: 0;
						}
					}
				}
			}
		}
	}
	// in general.scss the width was set to 200% to fit a 3rd button
	//.button-container {
	//	max-width: 50%;
	//}

	// this is extremely hacky but it works so yolo -will
	.lms-main-button {
		max-width: 75%;
	}
	.apply-pulse {
		min-width: 105%
	}
}


.lumos-events {
	background-color: white;
	background-size: cover;
	background-position: center center;
	padding-bottom: 30px;
	position: relative;
	padding-top: 100px;
}

.lms-section {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.event-cards-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	gap: 40px 40px;
	margin-top: 40px;
	z-index: 2;
	margin-bottom: 60px;

	@media only screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		margin: 40px 30px;
	}
}

.event-cards-container.center {
	grid-template-columns: 0.5fr 1fr 0.5fr;
	grid-template-areas: "left center right";

	.center {
		grid-area: center;
	}

	@media only screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-areas: "center";
		margin: 40px 30px;
	}
}

#events_page_container {
	// padding-left: 30px;
	margin-left: auto;
	margin-right: auto;
	background-size: cover;
	background-position: center center;
	align-items: center;
}

.active-events-container {
	padding: 80px 0;
	margin-top: 0;

	@media only screen and (max-width: 768px) {
		padding: 0;
		padding-top: 80px;
	}
}

.event-background-element {
	width: 120%;
	height: 400px;
	background-image: linear-gradient(43deg, #5e56c2 0%, #ef5274 100%);
	position: absolute;
	z-index: 0;
	transform: skew(0deg, -10deg) translateX(-50%);
	top: 20%;
	left: 50%;
	border-radius: 139px;

	@media only screen and (max-width: 768px) {
		height: 500px;
		top: 30%;
	}
}

.error-message {
	color: red;
}

.Vue-Toastification__container {
	margin-top: 100px;
}
</style>


